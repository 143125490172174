<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    height="100%"
    scrollable
    transition="dialog-bottom-transition"
    persistent
    no-click-animation
    v-resize="onResize"
    @keydown.esc="closeDialog()"
    @click:outside="closeDialog()"
  >
    <v-app-bar
      :absolute="true"
      :app="false"
      height="80"
      color="primary"
      dark
      flat
      style="z-index: 999"
    >
      <v-btn icon dark @click="closeDialog()">
        <v-icon v-text="'mdi-close'" />
      </v-btn>
      <v-app-bar-title class="text-no-wrap"> {{ title }} </v-app-bar-title>
      <v-chip
        v-if="beta"
        color="blue"
        dark
        :title="$t('common.availableIndefinitely')"
        x-small
        class="ms-2 font-weight-medium"
        >Beta
      </v-chip>
    </v-app-bar>

    <v-stepper
      v-model="_currentStep"
      :key="mobile"
      :disabled="loading"
      flat
      tile
      :vertical="mobile"
      class="px-0 mx-0 mt-12 pt-8 overflow-y-auto"
    >
      <div>
        <div v-if="mobile" class="mt-8 background fill-height">
          <div v-for="(step, i) in steps" :key="step.id">
            <v-stepper-step
              v-if="showSteps"
              :class="`${
                currentStep === step.id ? 'background rounded-t-lg' : ''
              } ${step.disabled ? 'disabled' : ''} py-4`"
              :step="step.id"
              @click="handleStepClick(step)"
            >
              <div class="primary--text text-none pl-2 font-weight-medium">
                {{ $t(step.titleKey) }}
              </div>
              <div class="primary--text pl-2 caption font-weight-regular">
                {{ $t(step.subtitleKey) }}
              </div>
              <v-chip
                class="ml-2 mt-2"
                color="success"
                v-if="step.disabled"
                x-small
                >{{ $t("common.soon") }}
              </v-chip>
              <div
                v-if="!$vuetify.breakpoint.mobile"
                class="pl-2 caption mt-1"
                style="line-height: 14px"
              >
                {{ $t(step.subtitleKey) }}
              </div>
            </v-stepper-step>
            <v-stepper-content :step="step.id" class="px-0 mx-0">
              <v-card flat tile class="overflow-y-auto px-2">
                <div v-if="currentStep == step.id">
                  <FullscreenStep :showDivider="showDivider">
                    <template #body>
                      <slot :name="`stepper-content.${step.id}`" />
                    </template>
                    <template #actions v-if="step.id != 1">
                      <slot :name="`stepper-content.actions`" />
                    </template>
                  </FullscreenStep>
                </div>
              </v-card>
            </v-stepper-content>
            <v-divider
              v-if="(i < steps.length - 1 && showDivider) || mobile"
              class="mb-3"
            />
          </div>
        </div>

        <div v-if="!mobile">
          <v-row
            :class="`${
              $vuetify.breakpoint.smAndDown ? 'mt-5' : 'primary'
            } ma-0 pa-0`"
            no-gutters
          >
            <v-col v-for="step in steps" :key="step.id">
              <v-stepper-step
                v-if="showSteps"
                :class="`${
                  currentStep === step.id ? 'background rounded-t-lg' : ''
                }  ${step.disabled ? 'disabled' : 'link'} py-4`"
                :complete="currentStep > step.id"
                :step="step.id"
                style="height: 100%"
                @click="handleStepClick(step)"
              >
                <div
                  :class="`${
                    currentStep === step.id ? 'primary--text' : 'white--text'
                  } pl-2 font-weight-medium`"
                >
                  {{ $t(step.titleKey) }}
                </div>
                <div
                  v-if="!$vuetify.breakpoint.mobile"
                  :class="`${
                    currentStep === step.id ? 'primary--text' : 'white--text'
                  } pl-2 caption mt-1`"
                  style="line-height: 14px"
                >
                  {{ $t(step.subtitleKey) }}
                </div>
                <v-chip
                  v-if="step.disabled"
                  class="ml-2 mt-2"
                  color="success"
                  x-small
                  >{{ $t("common.soon") }}
                </v-chip>
              </v-stepper-step>
            </v-col>
          </v-row>
          <v-stepper-items>
            <v-stepper-content
              v-for="step in steps"
              :key="step.id"
              :step="step.id"
            >
              <div v-if="currentStep == step.id">
                <FullscreenStep :showDivider="showDivider">
                  <template #body>
                    <slot :name="`stepper-content.${step.id}`" />
                  </template>
                  <template #actions v-if="step.id != 1">
                    <slot :name="`stepper-content.actions`" />
                  </template>
                </FullscreenStep>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </div>
      </div>
    </v-stepper>
  </v-dialog>
</template>

<script>
import FullscreenStep from "@/components/main/shared/FullscreenStep.vue";
import { getAnalytics, logEvent } from "firebase/analytics";
import { mapGetters } from "vuex";

export default {
  name: "FullscreenStepsDialog",

  components: { FullscreenStep },

  props: {
    show: { type: Boolean, default: () => false },
    steps: {
      type: Array,
      default: () => [
        {
          title: "",
          subtitle: ``,
          disabled: false,
          id: 1,
        },
      ],
    },
    currentStep: { type: Number, default: () => 1 },
    title: { type: String, default: () => "" },
    linear: { type: Boolean, default: () => false },
    canGoBack: { type: Boolean, default: () => false },
    beta: { type: Boolean, default: () => false },
    unavailable: { type: Boolean, default: () => false },
    showSteps: { type: Boolean, default: () => true },
    showDivider: { type: Boolean, default: () => true },
    loading: { type: Boolean, default: () => false },
    customChangeStepRule: { type: Function, default: () => null },
  },

  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },

  methods: {
    closeDialog() {
      this.$emit("close");
    },

    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },

    goToSelfCheckout() {
      const analytics = getAnalytics();

      logEvent(analytics, "go_to_self_checkout", {
        click: "Drive migration",
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description: "Conheça os planos na migração de arquivos",
      });

      this.$router.push({ name: "BillingUpgradePlan" });
    },

    isPreviousStep(step) {
      return step < this.currentStep;
    },

    handleStepClick(step) {
      if (this.customChangeStepRule && this.customChangeStepRule(step)) {
        this._currentStep = step.id;
      } else if (
        !step.disabled &&
        !this.linear &&
        this.canGoBack &&
        this.isPreviousStep(step.id)
      ) {
        this._currentStep = step.id;
      }
    },
  },

  computed: {
    ...mapGetters(["mainDomain", "currentUser"]),
    _currentStep: {
      get() {
        return this.currentStep;
      },
      set(value) {
        this.$emit("update:current-step", value);
      },
    },

    mobile() {
      return this.windowSize.x <= 959;
    },
  },

  mounted() {
    this.onResize();
  },
};
</script>

<style>
v-toolbar__extension {
  padding: 0px !important;
}

.v-stepper__step__step,
.v-stepper__step__step.primary {
  background-color: var(--v-accent-base) !important;
  border-color: var(--v-accent-base) !important;
}

.disabled > .v-stepper__step__step {
  background-color: rgb(199, 199, 199) !important;
  border-color: grey !important;
}
</style>
