<template>
  <div>
    <FullscreenStepsDialog
      :show="true"
      :title="$t('driveMigration.title')"
      :steps="steps"
      :current-step="step"
      :linear="true"
      :beta="true"
      :unavailable="unavailable"
      @update:current-step="step = $event"
      @close="() => this.$router.push({ name: 'Users' })"
    >
      <!-- Introdução -->
      <template #stepper-content.1>
        <FullscreenStepsIntro
          :image-src="driveMigrationImage"
          :title="$t('driveMigration.intro.title')"
          :description="$t('driveMigration.intro.description')"
          :benefits="[
            $t('driveMigration.intro.benefits.1'),
            $t('driveMigration.intro.benefits.2'),
            $t('driveMigration.intro.benefits.3'),
          ]"
          @start="() => (step = nextStep[step])"
        />
      </template>
      <!-- Processos -->
      <template #stepper-content.2>
        <!-- SCOPE ALERT  -->
        <Alert v-if="needAcceptScopes" color="warning" outlined>
          <v-row class="ma-0 pa-0" no-gutters align="center">
            <v-col cols="8">
              <h3 class="text-h6">
                <strong>{{ $t("scopesAlert.alertTitle") }}</strong>
              </h3>
              <span>
                {{ $t("scopesAlert.emailMigration.alertMessage") }}
              </span>
              <br />
              <span v-if="isAdmin">
                {{ $t("scopesAlert.driveMigration.resolveScopes") }}
              </span>
              <span v-else>
                {{ $t("scopesAlert.alertMessage3") }}
              </span>
            </v-col>
            <v-divider
              vertical
              class="my-4 info"
              style="opacity: 0.22"
            ></v-divider>
            <v-col cols="4" class="pa-0 ma-0 d-flex justify-space-around">
              <div>
                <v-btn
                  text
                  small
                  color="secondary lighten-2"
                  href="https://conectasuite.com/perguntas-frequentes/como-permitir-acesso-a-novos-escopos-no-painel-do-google-administrativo-2/"
                  target="_blank"
                  class="mr-2 text-body-2 font-weight-medium"
                >
                  {{ $t("action.knowMore") }}
                </v-btn>
                <v-btn
                  v-if="isAdmin"
                  text
                  small
                  outlined
                  color="warning"
                  :href="missingScopesText"
                  target="_blank"
                  class="ml-auto text-body-2 font-weight-medium"
                >
                  {{ $t("action.acceptScopes") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </Alert>
        <div class="d-flex align-center">
          <h1 class="font-weight-bold mb-3" style="font-size: 22px">
            {{ $t("driveMigration.processes.title") }}
          </h1>
        </div>
        <v-card-title class="d-flex flex-column align-start mb-5">
          <div class="text-subtitle-2 grey--text text--darken-1">
            {{ $t("driveMigration.processes.resume") }}
          </div>
          <div class="text-subtitle-1 grey--text text--darken-1">
            {{ $t("driveMigration.processes.start") }}
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="mx-0 px-0 py-0 mb-5">
          <v-data-table
            :items="Object.values(allDriveProcesses)"
            :headers="headersAll"
            :items-per-page="50"
            :loading="loading"
            :disabled="loading"
            single-expand
            fixed-header
            show-expand
            item-key="key"
            item
          >
            <template #[`item.actions`]="{ item }">
              <v-btn
                text
                :outlined="!loading && item.status === 'toDo'"
                color="accent"
                :dark="!loading && item.status === 'toDo'"
                :disabled="
                  loading || item.status !== 'toDo' || !hasConectaControl
                "
                class="px-1"
                @click="
                  () => (item.status === 'toDo' ? startProcess(item) : '')
                "
              >
                <v-icon
                  v-if="item.status === 'toDo'"
                  class="me-1"
                  size="24"
                  right
                  dark
                >
                  mdi-play
                </v-icon>
                {{
                  item.status !== "toDo"
                    ? $t("common.concluded")
                    : $t("common.resume")
                }}
              </v-btn>
            </template>

            <template #[`item.createdAt`]="{ item }">
              <div>
                {{ $t("common.theStart") }}:
                {{ formatTimestamp(item.createdAt) }}
              </div>
              <div v-if="item.modifiedAt">
                {{ $t("common.theFinish") }}:
                {{ formatTimestamp(item.modifiedAt) }}
              </div>
            </template>

            <template #[`item.status`]="{ item }">
              <div class="d-flex justify-space-between">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <div class="d-flex">
                      <v-icon
                        dark
                        v-bind="attrs"
                        v-on="on"
                        :color="getStatus(item, 'color')"
                        v-text="getStatus(item, 'icon')"
                        class="my-2 me-3"
                      />
                      <span class="font-weight-medium my-2">
                        {{ $t(getStatus(item, "textKey")) }}
                      </span>
                    </div>
                  </template>
                  <span class="font-weight-medium">
                    {{ $t(getStatus(item, "textKey")) }}
                  </span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.status === 'failed'">
                  <template #activator="{ on, attrs }">
                    <div class="d-flex justify-start">
                      <v-icon
                        color="grey"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        v-text="'mdi-help-circle'"
                      />
                    </div>
                  </template>
                  <span class="font-weight-medium">
                    {{ $t("driveMigration.processes.callSupport") }}
                  </span>
                </v-tooltip>
              </div>
            </template>

            <template #[`item.user_group`]="{ item }">
              <div>{{ $t("common.origin") }}: {{ item.user?.email }}</div>
              <div>{{ $t("common.destination") }}: {{ item.group }}</div>
            </template>

            <template #expanded-item="{ headers, item: process }">
              <td :colspan="headers.length" class="pa-3 grey lighten-4">
                <div class="d-flex flex-inline justify-space-around">
                  <div class="d-flex align-center">
                    <div class="font-weight-medium me-2">
                      {{ $t("common.author") }}:
                    </div>
                    <div>
                      <Author :author="findAuthor(process.createdBy) || {}" />
                    </div>
                  </div>

                  <div
                    v-for="(item, index) in getDriveMigrationStatistics(
                      process
                    )"
                    :key="`process_statistics_${index}`"
                  >
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <div class="d-flex align-center">
                          <v-badge
                            v-bind="attrs"
                            v-on="on"
                            :color="item.color"
                            :content="item.content"
                            class="mt-5 mb-3 me-6"
                          >
                            <v-icon
                              :color="item.color"
                              dark
                              v-text="item.icon"
                            />
                          </v-badge>
                          <span class="mt-5 mb-3 font-weight-medium">
                            {{ $t(item.tipKey) }}
                          </span>
                        </div>
                      </template>
                      <span class="font-weight-medium">
                        {{ $t(item.tipKey) }}
                      </span>
                    </v-tooltip>
                  </div>
                </div>
              </td>
            </template>

            <template #item.data-table-expand="{ item, isExpanded, expand }">
              <v-icon
                :class="[
                  'v-data-table__expand-icon',
                  { 'v-data-table__expand-icon--active': isExpanded },
                ]"
                @click.stop="expand(!isExpanded)"
                >$expand</v-icon
              >
            </template>
          </v-data-table>
          <InviteToUpsell
            v-if="!hasConectaControl"
            analyticsClick="offboarding_drive_transfer"
            invitationText="driveMigration.upsellInvitation"
            analyticsDescription="Conheça os planos no step 2 da transferencia do drive"
          />
        </v-card-text>
      </template>
      <!-- Iniciar -->
      <template #stepper-content.3>
        <v-form @submit.prevent="">
          <div class="d-flex align-center">
            <h1 class="font-weight-bold mb-3" style="font-size: 22px">
              {{ $t("driveMigration.start.title") }}
            </h1>
          </div>
          <span class="text-subtitle-2 grey--text text--darken-1">
            {{ $t("driveMigration.start.userTip") }}
          </span>
          <v-autocomplete
            v-model="user"
            :items="users"
            item-text="email"
            item-value="id_google"
            return-object
            :label="$t('common.selectUser')"
            clearable
            open-on-clear
            outlined
            class="mt-4"
            color="primary"
            :disabled="loading"
            :loading="loading"
          />
          <span class="text-subtitle-2 grey--text text--darken-1">
            {{ $t("driveMigration.start.groupTip") }}
          </span>
          <v-autocomplete
            v-model="group"
            :items="groups"
            item-text="email"
            item-value="id_google"
            return-object
            :label="
              user
                ? $t('driveMigration.start.selectGroup')
                : $t('driveMigration.start.tryUser')
            "
            clearable
            open-on-clear
            outlined
            color="primary"
            class="mt-4"
            :disabled="loading || !user"
            :loading="loading"
          />
          <!-- TODO: filter files by name "contains" AND
            adapt logic that gets files from main folder using Google Picker API
            https://developers.google.com/drive/picker/guides/overview
          -->
        </v-form>
        <InviteToUpsell
          v-if="!hasConectaControl"
          analyticsClick="offboarding_drive_transfer"
          invitationText="driveMigration.upsellInvitation"
          analyticsDescription="Conheça os planos no step 3 da transferencia do drive"
        />
      </template>
      <!-- Preparar -->
      <template #stepper-content.4>
        <v-card-text v-if="errorMessageKey">
          <div class="d-flex align-center">
            <h1 class="font-weight-bold mb-3" style="font-size: 22px">
              {{ $t("driveMigration.errors.title") }}
            </h1>
          </div>
          <div>{{ $t(errorMessageKey) }}</div>
        </v-card-text>
        <v-card-text v-else>
          <div class="d-flex align-center">
            <h1 class="font-weight-bold mb-3" style="font-size: 22px">
              {{
                loading
                  ? $t("driveMigration.prepare.loading")
                  : $t("driveMigration.prepare.ready")
              }}
              <p class="mt-3">
                {{ loading ? $t("driveMigration.prepare.waitFinish") : "" }}
              </p>
            </h1>
          </div>
          <div class="d-flex justify-start mt-5 mb-3 font-weight-medium">
            <v-simple-table style="max-width: 500px" :loading="loading">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="text-capitalize">{{ $t("common.user") }}:</td>
                    <td>{{ user?.email || "" }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("common.sharedDrive") }}:</td>
                    <td>{{ sharedDrive?.name || "" }}</td>
                  </tr>
                  <tr>
                    <td>
                      {{
                        loading
                          ? $t("driveMigration.prepare.filesAndFolders")
                          : $t("driveMigration.prepare.files")
                      }}:
                    </td>
                    <td>{{ driveArtifacts.length }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("driveMigration.prepare.batches") }}:</td>
                    <td>{{ Math.ceil(driveArtifacts.length / 100) }}</td>
                  </tr>
                  <tr v-if="loading">
                    <td>{{ $t("driveMigration.prepare.folders") }}:</td>
                    <td>
                      {{ foldersCount }}
                    </td>
                  </tr>
                  <tr v-if="loading">
                    <td>{{ $t("driveMigration.prepare.create") }}:</td>
                    <td>{{ foldersCreatedCount }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div
            v-if="!loading"
            class="mt-5 text-subtitle-1 grey--text text--darken-1 font-weight-medium"
          >
            {{ $t("driveMigration.prepare.continue") }}
          </div>
        </v-card-text>
      </template>
      <!-- Execução -->
      <template #stepper-content.5>
        <!-- Erro -->
        <v-card-text v-if="errorMessageKey">
          <div class="d-flex align-center">
            <h1 class="font-weight-bold mb-3" style="font-size: 22px">
              {{ $t("driveMigration.errors.title") }}
            </h1>
          </div>
          <div>{{ $t(errorMessageKey) }}</div>
        </v-card-text>
        <!-- Em andamento / Concluído -->
        <v-card-text v-else :loading="loading">
          <div class="d-flex align-center">
            <h3 class="mb-1" v-html="title"></h3>
          </div>
          <v-progress-linear
            color="primary"
            class="my-5 white--text"
            :value="progress"
            height="25"
            rounded
          >
            <strong>
              {{
                `${$t("offboardingUser.transferArquives")}: ${
                  userProcess?.transfered + userProcess?.failed
                } ${$t("common.of")} ${userProcess.totalFiles}`
              }}
            </strong>
          </v-progress-linear>
          <v-card-text v-if="!driveProcessStatistics.length">
            <v-skeleton-loader type="table" />
          </v-card-text>
          <div
            v-for="(item, index) in driveProcessStatistics"
            :key="`process_statistics_${index}`"
          >
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <div class="d-flex">
                  <v-badge
                    v-bind="attrs"
                    v-on="on"
                    :color="item.color"
                    :content="item.content"
                    class="mt-5 mb-3 me-9"
                  >
                    <v-icon :color="item.color" dark v-text="item.icon" />
                  </v-badge>
                  <span class="mt-5 mb-3 font-weight-medium">
                    {{ $t(item.tipKey) }}
                  </span>
                </div>
              </template>
              <span class="font-weight-medium"> {{ $t(item.tipKey) }} </span>
            </v-tooltip>
            <v-divider />
          </div>
        </v-card-text>
      </template>

      <!-- Ações e botões -->
      <template #stepper-content.actions>
        <v-btn
          height="52"
          large
          text
          class="text-none text-body-1 font-weight-bold px-8"
          :disabled="!stepBackAction[step]"
          @click="step = backStep[step]"
        >
          {{ stepBackAction[step] ? $t(stepBackAction[step]) : "" }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="step === 5"
          height="52"
          large
          outlined
          color="accent"
          class="text-none text-body-1 font-weight-bold px-8 me-3"
          @click="leaveProcessRoom"
        >
          <v-icon v-if="hasProcessRunning" class="me-1" size="24" right dark
            >mdi-pause</v-icon
          >
          {{ hasProcessRunning ? $t("common.pause") : $t("action.finish") }}
        </v-btn>
        <v-btn
          height="52"
          large
          color="accent"
          class="text-none text-body-1 font-weight-bold px-8"
          :disabled="
            (step === 4 && !!errorMessageKey) ||
            (step !== 5 &&
              ((step === 3 && (!user?.key || !group || !hasConectaControl)) ||
                (step === 4 && (!user?.key || !group)) ||
                (step === 2 && needAcceptScopes) ||
                !stepNextAction[step] ||
                loading))
          "
          @click="buttonAction"
          :loading="step !== 5 && loading"
        >
          {{ stepNextAction[step] ? $t(stepNextAction[step]) : "" }}
          <v-icon size="24" right dark>{{
            step !== 5 ? "mdi-arrow-right" : "mdi-window-minimize"
          }}</v-icon>
        </v-btn>
      </template>
    </FullscreenStepsDialog>
    <HeaderDialog
      id="confirm_migration"
      :title="$t('driveMigration.start.startTransfer')"
      :show="showConfirmDialog"
      width="700"
      :persistent="false"
      @close="showConfirmDialog = !showConfirmDialog"
      @action="confirmTransfer()"
    >
      <template v-slot:body>
        <v-row class="mx-0">
          <v-col class="mx-0">
            <v-card flat>
              <v-card-title style="gap: 5px"
                >{{ $t("driveMigration.start.userAccessDrive") }}
                <TooltipIcon
                  color="primarySuite"
                  :label="$t('driveMigration.start.tooltipLabel')"
                  size="23"
                  icon="mdi-information"
                  max-width="500"
              /></v-card-title>
              <Alert v-if="externalMembers.length" color="warning">
                <span>{{ $t("driveMigration.start.hasExternalMember") }}</span>
              </Alert>
              <v-virtual-scroll
                :height="`${groupMembers.length >= 10 ? '250' : '180'}`"
                item-height="25"
                :items="groupMembers"
              >
                <template v-slot:default="{ item }">
                  <v-list-item>
                    <v-list-item-content
                      :class="`${
                        externalMembers.includes(item) ? 'font-weight-bold' : ''
                      }`"
                    >
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="externalMembers.includes(item)">
                      <v-btn depressed small>
                        <v-icon color="orange darken-4" left>
                          mdi-alert
                        </v-icon>
                        {{ $t("common.external") }}
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </HeaderDialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import { INCREMENTAL_SCOPES } from "@/helpers/variables/scopes";
import { DISCOVERY_DOCS } from "@/helpers/variables/discoveryDocs";

import InviteToUpsell from "@/components/purchase/InviteToUpsell.vue";
import FullscreenStepsDialog from "@/components/main/shared/FullscreenStepsDialog.vue";
import FullscreenStepsIntro from "@/components/main/shared/FullscreenStepsIntro.vue";
import Author from "@/components/logs/cells/Author.vue";

import { getDriveMigrationStatistics } from "@/helpers/services/utils";

export default {
  name: "DriveMigration",

  components: {
    FullscreenStepsDialog,
    FullscreenStepsIntro,
    Author,
    InviteToUpsell,
  },

  data: () => ({
    driveMigrationImage: require("@/assets/images/drive-migration.png"),
    status: {
      unknown: {
        textKey: "common.unknown",
        icon: "mdi-shield-alert",
        color: "warning",
      },
      toDo: {
        textKey: "common.unfinished",
        icon: "mdi-clock",
        color: "accent",
      },
      done: {
        textKey: "common.concluded",
        icon: "mdi-shield-check",
        color: "success",
      },
      doneWithFailures: {
        textKey: "common.concludedWithFailures",
        icon: "mdi-shield-check",
        color: "warning",
      },
      failed: {
        textKey: "common.failed",
        icon: "mdi-shield-off",
        color: "error",
      },
    },

    step: 1,
    steps: [
      {
        titleKey: "driveMigration.steps.intro.title",
        subtitleKey: "driveMigration.steps.intro.subtitle",
        id: 1,
      },
      {
        titleKey: "driveMigration.steps.processes.title",
        subtitleKey: "driveMigration.steps.processes.subtitle",
        id: 2,
      },
      {
        titleKey: "driveMigration.steps.start.title",
        subtitleKey: "driveMigration.steps.start.subtitle",
        id: 3,
      },
      {
        titleKey: "driveMigration.steps.prepare.title",
        subtitleKey: "driveMigration.steps.prepare.subtitle",
        id: 4,
      },
      {
        titleKey: "driveMigration.steps.executing.title",
        subtitleKey: "driveMigration.steps.executing.subtitle",
        id: 5,
      },
    ],
    stepBackAction: {
      1: "",
      2: "common.back",
      3: "common.back",
      4: "common.back",
      5: "",
    },
    stepNextAction: {
      1: "",
      2: "driveMigration.startProcess",
      3: "common.prepare",
      4: "common.advance",
      5: "action.minimize",
    },
    backStep: {
      1: 1,
      2: 1,
      3: 2,
      4: 2,
      5: 2,
    },
    nextStep: {
      1: 2,
      2: 3,
      3: 4,
      4: 5,
      5: 2,
    },

    user: null,
    errorMessageKey: "",

    loadingUserGroups: false,

    groupScope: INCREMENTAL_SCOPES.ADMIN_DIRECTORY_GROUP,
    driveScope: INCREMENTAL_SCOPES.ADMIN_DRIVE,
    needAcceptScopes: false,
    group: null,
    groups: [],

    unavailable: null,
    unavailableSrc: require("@/assets/images/imagem_conecta_suite.jpg"),

    showConfirmDialog: false,
    groupMembers: [],
    externalMembers: [],
  }),

  computed: {
    ...mapGetters([
      "users",
      "scopeCode",
      "userGroups",
      "isAdmin",
      "company",
      "sharedDrive",
      "driveArtifacts",
      "foldersCount",
      "foldersCreatedCount",
      "allDriveProcesses",
      "runningDriveProcessPath",
      "driveProcessStatistics",
      "loadingDriveMigration",
      "hasIntermediaryPartner",
      "hasBillingPermission",
      "hasConectaControl",
      "hasProcessRunning",
      "userProcess",
    ]),
    missingScopesText() {
      return `https://admin.google.com/ac/apps/gmail/marketplace/appdetails/${process.env.VUE_APP_MARKETPLACE_APP_ID}`;
    },
    loading() {
      return (
        !this.users?.length ||
        this.loadingDriveMigration ||
        this.loadingUserGroups
      );
    },
    groupScopeCode() {
      return this.scopeCode(this.groupScope);
    },
    headersAll() {
      return [
        {
          value: "createdAt",
          text: this.$t("common.date"),
          sortable: true,
        },
        {
          value: "status",
          text: this.$t("common.status"),
          sortable: true,
          width: 190,
        },
        {
          value: "user_group",
          text: `${this.$t("common.origin")}/${this.$t("common.destination")}`,
          sortable: false,
        },
        {
          value: "actions",
          text: "",
          sortable: false,
          width: 110,
          align: "center",
        },
      ];
    },
    progress() {
      if (!this.hasProcessRunning) return;
      const { totalFiles, failed, transfered } = this.userProcess;
      return (failed + transfered / totalFiles) * 100;
    },
    title() {
      return this.$t("driveMigration.executing.title", {
        email: this.user?.email,
        group: this.group,
      });
    },
  },

  watch: {
    // TODO: Este método é próprio da migração de drive
    async user(user, oldUser) {
      if (user?.key == oldUser?.key) return;
      if (user?.key && !this.runningDriveProcessPath && this.step === 3) {
        this.group = null;
        const groups = this.userGroups(user.id_google);
        if (!groups?.length) await this.loadGroups(user);
        else this.groups = groups;
      }
    },
    async step(step, oldStep) {
      if (step == oldStep) return;
      this.errorMessageKey = "";
      const payload = { group: this.group, user: this.user };
      this.setDriveArtifacts([]);
      if (step === 4) {
        if (!this.hasConectaControl) return;

        const result = await this.createDriveTransference(payload);
        if (result) {
          this.errorMessageKey = result;
        }
      } else if (step === 5) {
        if (!this.hasConectaControl) return;

        this.startDriveTransference(payload);
      }
    },
    runningDriveProcessPath(path, oldPath) {
      const oldProcessKey = oldPath && oldPath.split("/")[3];
      const oldProcess = this.allDriveProcesses[oldProcessKey];
      if (path === "" && oldProcess && oldProcess.status !== "toDo") {
        this.step = 2;
      }
    },

    hasProcessRunning(value) {
      if (!value && this.step === 5) {
        this.step = 2;
      }
    },
  },

  methods: {
    goToPlans() {
      this.$router.push({ name: "BillingUpgradePlan" });
    },
    getDriveMigrationStatistics,

    formatTimestamp(timestamp) {
      return new Date(timestamp).toLocaleDateString(
        localStorage?.getItem?.("lang") ||
          window?.navigator?.language ||
          "pt-BR",
        {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }
      );
    },
    getStatus(process, type) {
      const status =
        process.failed && process.status === "done"
          ? "doneWithFailures"
          : process.status;
      return this.status[status]?.[type] || "common.unknown";
    },
    findAuthor(email) {
      return this.users?.find((user) => user.email == email) || {};
    },
    ...mapMutations({
      setDriveArtifacts: "set.driveArtifacts",
      setShowTasksProgress: "setShowTasksProgress",
    }),
    ...mapActions([
      "checkScopes",
      "getGroupsByUser",
      "loadGAPIScript",
      "getDriveTransferences",
      "createDriveTransference",
      "startDriveTransference",
      "leaveDriveProcessRoom",
      "getGroupMembers",
    ]),

    async loadGroups(user) {
      this.loadingUserGroups = true;
      let groupScopeCode = "";
      if (!this.groupScopeCode) {
        const response = await this.checkScopes({
          scopes: [this.groupScope],
        });
        groupScopeCode = response.data.code;
      }
      if ((this.groupScopeCode || groupScopeCode) !== "ACCEPTED_SCOPES") return;
      await this.getGroupsByUser(user);
      this.groups = this.userGroups(user.id_google) || [];
      this.loadingUserGroups = false;
    },
    async startProcess(process) {
      if (!this.hasConectaControl) return;
      this.user = process.user;
      this.group = process.group;
      this.step = 5;
    },
    leaveProcessRoom() {
      this.user = null;
      this.group = null;
      this.groups = [];
      this.errorMessageKey = "";
      this.step = 2;
      if (!this.runningDriveProcessPath) return;
      const status = this.hasProcessRunning ? "toDo" : "done";
      this.leaveDriveProcessRoom({ status: status });
    },
    exit() {
      this.setShowTasksProgress(true);
      this.$router.push({ name: "Users" });
    },

    confirmTransfer() {
      this.showConfirmDialog = false;
      this.step = this.nextStep[this.step];
    },

    async buttonAction() {
      if (this.step === 3) {
        if (!this.groupMembers.length) {
          this.loadingUserGroups = true;
          await this.getGroupMembers(this.group)
            .then(({ data }) => {
              this.groupMembers = data;
              if (this.groupMembers.length) {
                this.checkhasExternalMember();
                return;
              }
            })
            .finally(() => (this.loadingUserGroups = false));
        } else {
          this.checkhasExternalMember();
          return;
        }
        return;
      }
      return this.step !== 5
        ? (this.step = this.nextStep[this.step])
        : this.exit();
    },

    checkhasExternalMember() {
      const companyDomainsSet = new Set(this.company.domains_list);

      this.externalMembers = this.groupMembers.filter((email) => {
        const domain = email.split("@")[1];
        return !companyDomainsSet.has(domain);
      });
      this.showConfirmDialog = true;
    },
  },

  async beforeMount() {
    if (this.hasProcessRunning) {
      this.user = this.userProcess.user;
      this.group = this.userProcess.group;
      this.step = 5;
      return;
    }
    await this.checkScopes({
      scopes: [this.groupScope, this.driveScope],
    })
      .then(async () => {
        this.needAcceptScopes = false;
        await this.loadGAPIScript({
          scopes: [this.driveScope],
          discoveryDocs: [DISCOVERY_DOCS.DRIVE],
        });
        await this.getDriveTransferences();
      })
      .catch(() => {
        this.needAcceptScopes = true;
      });
  },
};
</script>

<style>
.drive-migration .v-select__selections {
  line-height: 24px;
}

v-toolbar__extension {
  padding: 0px !important;
}
</style>
