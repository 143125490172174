<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" class="d-flex align-center">
        <v-avatar size="20" left class="mr-1">
          <img :src="getUserAvatar(author.email)" :alt="verifyAuthorName" />
        </v-avatar>
        <span class="text-truncate" style="max-width: 125px">
          {{ verifyAuthorName }}
        </span>
      </span>
    </template>
    <span>
      <b>{{ verifyAuthorName }}</b>
    </span>
  </v-tooltip>
</template>

<script>
import defaultUserPhoto from "@/assets/user-default.png";
import { mapGetters } from "vuex";

export default {
  name: "Author",

  props: {
    author: { type: Object, required: true },
  },

  methods: {
    getUserAvatar(user_email) {
      const user = this.users.find((user) => user.email === user_email);

      if (user) {
        return user.photo;
      }

      return defaultUserPhoto;
    },
  },

  computed: {
    ...mapGetters(["users"]),
    verifyAuthorName() {
      return this.author.name || "Não encontrado";
    },
  },
};
</script>
