<template>
  <v-container
    style="height: 100%"
    class="d-flex justify-center align-center flex-column"
  >
    <v-row no-gutters style="width: 90vw">
      <v-col
        :class="`d-flex ${
          $vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-end'
        } align-center`"
      >
        <v-img :src="imageSrc" width="100%" max-width="510px" />
      </v-col>
      <v-col
        :class="`d-flex ${
          $vuetify.breakpoint.smAndDown ? 'justify-center' : 'justify-start'
        } align-center`"
      >
        <div
          :class="`${$vuetify.breakpoint.smAndDown ? 'mx-10' : 'ma-10'}`"
          style="line-height: 1.25; max-width: 510px"
        >
          <h1 class="line">{{ title }}</h1>
          <h3 class="my-3 font-weight-regular">
            {{ description }}
          </h3>
          <div class="py-5">
            <tr
              v-for="(benefit, index) in benefits"
              :key="`benefit-${index}`"
              class="d-flex align-baseline"
            >
              <td width="36" height="48" class="me-2">
                <v-icon size="26" color="success">mdi-check-circle </v-icon>
              </td>
              <td>{{ benefit }}</td>
            </tr>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-btn
      height="58"
      class="mt-16 text-none text-h6 font-weight-bold px-8"
      large
      color="accent"
      @click="() => $emit('start')"
      >{{ $t("common.start") }}
      <v-icon size="24" right dark>mdi-arrow-right</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: "FullscreenStepsIntro",

  props: {
    imageSrc: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
    description: {
      type: String,
      default: () => "",
    },
    benefits: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
